import React, { Fragment, useEffect, useState } from "react";
import { Button, Dropdown, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import profile from "../../../images/profile/profile.png";
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const MiPerfil = () => {
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (storedUserDetails) {
      setUserDetails(storedUserDetails.userData);
    }
  }, []);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="profile card card-body px-3 pt-3 pb-0">
              <div className="profile-head">
                <div className="photo-content">
                  <div className="cover-photo rounded"></div>
                </div>
                <div className="profile-info">
                  <div className="profile-photo">
                    <img src={profile} className="img-fluid rounded-circle" alt="profile" />
                  </div>
                  <div className="profile-details">
                    <div className="profile-name px-3 pt-2">
                      <h4 className="text-primary mb-0">{userDetails?.name || 'Nombre no disponible'}</h4>
                      <p className="text-capitalize">Rol: {userDetails?.role?.role || 'Rol no disponible'}</p>
                    </div>
                    <div className="profile-email px-2 pt-2">
                      <h4 className="text-muted mb-0">{userDetails?.email || 'Correo no disponible'}</h4>
                      <p>Email</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Información adicional y configuraciones */}
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="profile-tab">
                  <div className="custom-tab-1">
                    <Tab.Container defaultActiveKey='About'>
                      <Nav as='ul' className="nav nav-tabs">
                        <Nav.Item as='li' className="nav-item">
                          <Nav.Link to="#about-me" eventKey='About'>Sobre mí</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane id="about-me" eventKey='About'>
                          <div className="profile-personal-info mt-4 ps-2">
                            <h4 className="text-primary mb-4">Información Personal</h4>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">Nombre<span className="pull-right">:</span></h5>
                              </div>
                              <div className="col-9">
                                <span>{userDetails?.name || 'Nombre no disponible'}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">Email<span className="pull-right">:</span></h5>
                              </div>
                              <div className="col-9">
                                <span>{userDetails?.email || 'Correo no disponible'}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">Rol<span className="pull-right">:</span></h5>
                              </div>
                              <div className="col-9">
                                <span>{userDetails?.role?.role || 'Rol no disponible'}</span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">Teléfono<span className="pull-right">:</span></h5>
                              </div>
                              <div className="col-9">
                                <span>{userDetails?.phone || 'Teléfono no disponible'}</span>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane id="profile-settings" eventKey='Setting'>
                          <div className="pt-3">
                            <div className="settings-form">
                              <h4 className="text-primary">Configuración de la cuenta</h4>
                              <form onSubmit={(e) => e.preventDefault()}>
                                {/* Puedes agregar aquí campos de edición de perfil */}
                                <button className="btn btn-primary" type="submit">Guardar cambios</button>
                              </form>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MiPerfil;

import React, { useState, useContext, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import LogoutPage from './Logout';
import { IMAGES, SVGICON } from "../../constant/theme";
import { ThemeContext } from "../../../context/ThemeContext";

const Header = ({ onNote }) => {
  const [headerFix, setHeaderFix] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedCommerce, setSelectedCommerce] = useState(null);
  const [selectedSalesPoint, setSelectedSalesPoint] = useState(null);

  // Función para cargar los datos del localStorage
  const loadDataFromLocalStorage = () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const commerce = JSON.parse(localStorage.getItem('selectedCommerce'));
    const salesPoint = JSON.parse(localStorage.getItem('selectedSalesPoint'));

    if (userDetails && userDetails.userData) {
      setUserData(userDetails.userData);
    }
    if (commerce) {
      setSelectedCommerce(commerce);
    }
    if (salesPoint) {
      setSelectedSalesPoint(salesPoint);
    }
  };

  // useEffect para cargar los datos al montar el componente y escuchar cambios en el localStorage
  useEffect(() => {
    loadDataFromLocalStorage();

    // Escuchar cambios en el localStorage
    window.addEventListener('storage', loadDataFromLocalStorage);

    // Limpiar el evento al desmontar el componente
    return () => {
      window.removeEventListener('storage', loadDataFromLocalStorage);
    };
  }, []);

  const { background, changeBackground } = useContext(ThemeContext);

  const handleThemeMode = () => {
    if (background.value === 'dark') {
      changeBackground({ value: "light", label: "Light" });
    } else {
      changeBackground({ value: "dark", label: "Dark" });
    }
  };

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"></div>
            <div className="header-right d-flex align-items-center">
              <div className="input-group search-area">
                <input type="text" className="form-control" placeholder="Buscar..." />
                <span className="input-group-text">
                  <Link to={"#"}>{SVGICON.SearchIcon}</Link>
                </span>
              </div>
              <ul className="navbar-nav ">
                <li className="nav-item dropdown notification_dropdown">
                  <Link
                    to={'#'}
                    className="nav-link bell dz-theme-mode"
                    onClick={() => handleThemeMode()}
                  >
                    {background.value === "dark" ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z" fill="#000000" fillRule="nonzero" />
                          <path d="M19.5,10.5 L21,10.5 C21.8284271,10.5 22.5,11.1715729 22.5,12 C22.5,12.8284271 21.8284271,13.5 21,13.5 L19.5,13.5 C18.6715729,13.5 18,12.8284271 18,12 C18,11.1715729 18.6715729,10.5 19.5,10.5 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                        </g>
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <rect x="0" y="0" width="24" height="24" />
                          <path d="M12.0700837,4.0003006 C11.3895108,5.17692613 11,6.54297551 11,8 C11,12.3948932 14.5439081,15.9620623 18.9299163,15.9996994 C17.5467214,18.3910707 14.9612535,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 C12.0233848,4 12.0467462,4.00010034 12.0700837,4.0003006 Z" fill="#000000" />
                        </g>
                      </svg>
                    )}
                  </Link>
                </li>

                <li className="nav-item ps-3">
                  <Dropdown className="header-profile2">
                    <Dropdown.Toggle className="nav-link i-false" as="div">
                      <div className="header-info2 d-flex align-items-center">
                        <div className="header-media">
                          <img src={IMAGES.User} alt="" />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <div className="card border-0 mb-0">
                        <div className="card-header py-2">
                          <div className="products">
                            <img src={IMAGES.User} className="avatar avatar-md" alt="" />
                            <div>
                              <h6>{userData ? userData.name : "Nombre Usuario"}</h6>
                              <span className="text-capitalize">
                                {userData ? userData.role.role.replace("-", " ") : "Rol"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-body px-0 py-2">
                          <Link to={"/mi-perfil"} className="dropdown-item ai-icon ">
                            {SVGICON.UserSvg}{" "}
                            <span className="ms-2">Mi Perfil</span>
                          </Link>
                          {/* <Link to={"#"} className="dropdown-item ai-icon ">
                            {SVGICON.Message}{" "}
                            <span className="ms-2">Mensajes </span>
                          </Link>
                          <Link to={"/email-inbox"} className="dropdown-item ai-icon ">
                            {SVGICON.Notification} {" "}
                            <span className="ms-2">Notificaciones </span>
                          </Link> */}
                        </div>
                        <div className="card-footer px-0 py-2">
                          {selectedCommerce && (
                            <Link className="dropdown-item ai-icon ">
                              <i style={{ fontSize: "large", color: "#1b2232" }} className="fa-sharp fa-light fa-store"></i>{" "}
                              <span className="ms-2">{selectedCommerce.label}</span>
                            </Link>
                          )}
                          {selectedSalesPoint && (
                            <Link className="dropdown-item ai-icon ">
                              <i style={{ fontSize: "large", color: "#1b2232" }} className="fa-sharp fa-light fa-square-share-nodes"></i>{" "}
                              <span className="ms-2">{selectedSalesPoint.label}</span>
                            </Link>
                          )}
                        </div>
                        <div className="card-footer px-0 py-2">
                          <LogoutPage />
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;

import { lazy, Suspense, useEffect, useState } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import {  Route, Routes, useLocation , useNavigate , useParams } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./jsx/other/swiper/swiper-bundle.min.css";
import "./jsx/other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Error404 from './jsx/pages/Error404';


const RecoveryPassword = lazy(() => import('./jsx/pages/RecoveryPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
	
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}



function App (props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();    
      useEffect(() => {
        checkAutoLogin(dispatch, navigate);    
      }, []);

    
    let routeblog = (         
      <Routes>   
        <Route  path='/login' element={<Login />} />
        <Route path='/recovery-password' element={<RecoveryPassword />} />        
      </Routes> 
    );
    if (props.isAuthenticated) {
		  return (
			  <>
            <Suspense fallback={              
                <div id="preloader">                
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>  
              }
            >
              <Index /> 
            </Suspense>
        </>
      );    
	}else{
		return (
			  <div className="vh-100">
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
              }
            >
                {routeblog}
                
            </Suspense>
			  </div>
		);
	}
};


const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

//export default connect((mapStateToProps)(App)); 
export default withRouter(connect(mapStateToProps)(App)); 

